import { Controller } from "@hotwired/stimulus";

import SlimSelect from "slim-select";

require("slim-select/dist/slimselect.min.css");

export default class extends Controller {
  static targets = [
    "template",
    "product",
    "items",
    "total",
    "subTotal",
    "warehouse",
    "tax",
    "shipping",
    "discount",
    "tTax",
    "tShipping",
    "tDiscount",
    "grandTotal",
    "vatSection",
    "paymentSection",
    "taxCost",
    "taxTotal",
    "discountPercent",
    "quantity",
    "unitCost",
    "vat",
    "vatableAmount"
  ];

  static values = {
    itemizable: String,
    persisted: String,
    autofillUnitPrice: String,
  };

  connect() {
    this.product = null;
    this.computeTotal();
  }

  paymentStatusChanged(event) {
    event.preventDefault();
    if (this.hasPaymentSectionTarget) {
      if (event.target.value == "pending") {
        this.paymentSectionTarget.classList.remove("d-block");
        this.paymentSectionTarget.classList.add("d-none");
      } else {
        this.paymentSectionTarget.classList.add("d-block");
      }
    }
  }

  taxTypeChanged(event) {
    event.preventDefault();
    if (this.hasVatSectionTarget) {
      if (event.target.value == "inclusive") {
        
      } else if(event.target.value == "exclusive") {
        
      }
    }
  }

  computeSubCost(event) {
    event.preventDefault();
    var item = event.target.closest(".nested-fields");
    var values = this.getItemValues(item);
    if (this.hasItemizableValue && this.itemizableValue == "Sale") {
      item.classList.remove("bg-warning", "text-dark");
      if (parseFloat(values.unit_cost) < parseFloat(values.min_unit_cost)) {
        // Highlight underselling
        item.classList.add("bg-warning", "text-dark");
      }
    }
   
    var sub_cost;
    var p_discount = parseFloat(values.discount_percentage) || 0;
    var discounted;
    if (p_discount > 0) {
      discounted = 1 - (p_discount / 100);
      sub_cost = values.unit_cost * values.quantity * discounted;
    } else {
      sub_cost = values.unit_cost * values.quantity;
    }
    item.querySelector("input[name*='sub_cost']").value = sub_cost;
    this.computeTotal();
  }

  format(number) {
    return Intl.NumberFormat().format(number);
  }

  computeTotal() {
    // TODO: refactor, cater for tax
    if (this.hasVatTarget) {
      console.log(this.vatTarget.value)
    } 
    var itemsCost = this.subTotalTargets
      .filter(
        (target) =>
          target
            .closest(".nested-fields")
            .querySelector("input[name*='_destroy']").value != 1
      )
      .reduce((total, subt) => {
        return total + parseFloat(subt.value);
      }, 0);

    if (this.hasShippingTarget) {
      var shippingCost = parseFloat(this.shippingTarget.value) || 0;
    }
    if (this.hasDiscountTarget) {
      var discount = parseFloat(this.discountTarget.value) || 0;
    }
    if (this.hasTotalTarget) {
      this.totalTarget.textContent = this.format(itemsCost);
    }
    if (this.hasTShippingTarget) {
      this.tShippingTarget.textContent = this.format(shippingCost) || 0;
    }
    if (this.hasTDiscountTarget) {
      this.tDiscountTarget.textContent = this.format(discount);
    }
    let tax;
    let total;
    if (this.hasGrandTotalTarget && this.hasVatTarget && this.vatTarget.value == 'Exclusive') {
      let totalItemsCost = (itemsCost - discount) * 1.16
      tax = totalItemsCost - (itemsCost - discount);
      total = totalItemsCost + shippingCost
      this.vatableAmountTarget.textContent = this.format(itemsCost)
      this.tTaxTarget.textContent = this.format(tax)
      this.grandTotalTarget.textContent = this.format(total);
    } else if (this.hasGrandTotalTarget &&this.hasVatTarget && this.vatTarget.value == 'Inclusive') {
      let vatable_amount = (itemsCost - discount) / 1.16
      tax = (itemsCost - discount) - vatable_amount
      total = itemsCost + shippingCost - discount
      this.vatableAmountTarget.textContent = this.format(vatable_amount)
      this.tTaxTarget.textContent = this.format(tax)
      this.grandTotalTarget.textContent = this.format(total);
    } else if (this.hasGrandTotalTarget && this.hasTTaxTarget && this.hasVatableAmountTarget ) {
      total = itemsCost + shippingCost - discount
      this.vatableAmountTarget.textContent = this.format(itemsCost)
      this.tTaxTarget.textContent = this.format(0)
      this.grandTotalTarget.textContent = this.format(total);
    } else if (this.hasGrandTotalTarget) {
      total = itemsCost + shippingCost - discount
      this.grandTotalTarget.textContent = this.format(total);
    }
  }

  computeTaxTotal(){
    var itemsTaxCost = this.taxCostTargets
      .filter(
        (target) =>
          target
            .closest(".nested-fields")
            .querySelector("input[name*='_destroy']").value != 1
      )
      .reduce((total, subt) => {
        return total + parseFloat(subt.value);
      }, 0);

      if(this.hasTaxTotalTarget){
        this.taxTotalTarget.textContent = this.format(itemsTaxCost)
      }
  }

  computeGrandTotal(event) {
    this.computeTotal();
  }

  addTableRow(event) {
    event.preventDefault();
    var product_id = this.productSelectController.productTarget.value;
    if (product_id !== "undefined") {
      this.getProduct(product_id);
    }
  }

  get productSelectController() {
    // https://github.com/hotwired/stimulus/issues/35
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "product-select"
    );
  }

  getItemValues(item) {
    var disc = item.querySelector("input[name*='discount_percent_field']");
    return {
      unit_cost: item.querySelector("input[name*='unit_cost']").value,
      quantity: item.querySelector("input[name*='quantity']").value,
      sub_cost: item.querySelector("input[name*='sub_cost']").value,
      discount_percentage: disc ? disc.value : 0,
      min_unit_cost: item.querySelector("input[name*='unit_cost']").dataset
        .unitcost,
    };
  }

  addAssociation() {
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      // new Date().valueOf()
      this.itemsTarget.querySelectorAll(".nested-fields").length
    );
    if (this.persistedValue == "true") {
      this.templateTarget.insertAdjacentHTML("beforebegin", content);
    } else {
      this.templateTarget.insertAdjacentHTML("afterend", content);
    }
    this.addFieldValues();
  }

  // FIXME: should not add multiple line items for the same product
  addFieldValues() {
    var items = this.itemsTarget.querySelectorAll(".nested-fields");
    if (this.persistedValue == "true") {
      var item = items[items.length - 1];
    } else {
      var item = items[0];
    }
    item.querySelector("input[name*='product_id']").value = this.product.id;
    item.querySelector(
      "input[name*='product_name']"
    ).value = `${this.product.name} (${this.product.code})`;
    if (this.hasItemizableValue && this.itemizableValue == "Purchase") {
      item.querySelector("input[name*='unit_cost']").value =
        this.product.price.buying_price;
      item.querySelector("input[name*='sub_cost']").value =
        this.product.price.buying_price;
    } else {
      if (this.autofillUnitPriceValue == "true") {
        item.querySelector("input[name*='unit_cost']").value =
          this.product.price.retail_price;
          item.querySelector("input[name*='sub_cost']").value =
            this.product.price.retail_price;
      } else {
        item.querySelector("input[name*='unit_cost']").value = 0;
        item.querySelector("input[name*='sub_cost']").value = 0;
      }
    }

    item.querySelector("input[name*='unit_cost']").dataset.unitcost =
      this.product.price.retail_price;

    item.querySelector("input[name*='quantity']").value = 1;
    this.computeTotal();

    if (this.product.variants.length > 0) {
      this.addVariants(item);
    }
  }

  addVariants(item) {
    var selectVariant = item.querySelector("select[name*=variants]");
    selectVariant.innerHTML = "";

    var filtered_variants = this.product.variants.filter((variant, i) => {
      return variant.warehouse_id == this.warehouseTarget.value;
    });

    filtered_variants.forEach((variant, i) => {
      selectVariant.innerHTML +=
        '<option value ="' + variant.id + '">' + variant.name + "</option>";
    });

    // add variantvalues with first variant
    var variant = filtered_variants[0];
    if (variant) {
      this.addVariantValues(item, variant.id, "(" + variant.name + ")");
    }
  }

  addVariantValues(item, value, text) {
    item.querySelector("input[name*='product_variant_id']").value = value;
    var value = item.querySelector("input[name*='product_name']").value;

    if (/\(.*\)/.test(value)) {
      item.querySelector("input[name*='product_name']").value = value.replace(
        /\(.*\)/,
        text
      );
    } else {
      item.querySelector("input[name*='product_name']").value =
        value + " " + text;
    }
  }

  // FIXME: line items removed from ui are still submitted with the form
  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
    this.computeTotal();
  }

  getProduct(id) {
    // TODO: persist and use result data from searchProducts
    Rails.ajax({
      type: "GET",
      url: `/products/${id}.json`,
      success:  (data, status, xhr) => {
        this.product = data;
        this.addAssociation();
      },
    });
  }
}
