import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "countType", "partialSection" ]

  connect() {
   console.log('connected')
  }

  countTypeChanged(event){
    event.preventDefault()
    this.partialSectionTarget.classList.toggle("d-none")
  }
}
