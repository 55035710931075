
import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"
import "intl-tel-input/build/css/intlTelInput.min.css"

export default class extends Controller {
  static targets = [ "tel" ]

  connect() {
    this.iti = intlTelInput(this.telTarget, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js",
      initialCountry: "KE",
      autoPlaceholder: "polite",
      // separateDialCode: true,
      // initialCountry: "auto",
      // geoIpLookup: async function (success, failure) {
      //   const resp = await get("https://ipinfo.io", {
      //     responseKind: "jsonp"
      //   })
      //   var countryCode = resp && resp.country ? resp.country : "KE";
      //   success(countryCode);
      // },
    });
  }

  focusOut(event){
    event.target.value = this.iti.getNumber()
  }
}
