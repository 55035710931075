import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "total", "subCost"]

  setTotal(){
    this.totalTarget.textContent = this.subCostTargets.reduce((total, subcost) => { return total + parseFloat(subcost.textContent)}, 0)
  }
}
