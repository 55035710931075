import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"

export default class extends Controller {
    static targets = ["link", "message"];
    clipboard;

    connect() {
        this.clipboard = new ClipboardJS(this.linkTarget);
        this.clipboard.on('success', (e) => {
            this.messageTarget.innerText = "Copied!";
            e.clearSelection();
        });
        this.clipboard.on('error', (e) => {
            console.error('Failed to copy: ', e);
            this.messageTarget.innerText = "Error!";
        });
    }
  
    disconnect() {
        this.clipboard.destroy();
    }

}