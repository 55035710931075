import { Controller } from "@hotwired/stimulus";
import { TemplateInstance } from "@github/template-parts";

export default class extends Controller {
  connect() {}

  addItem(event) {
    var template = this.cloneController.templateTarget;

    var selected = event.target.options[event.target.selectedIndex];
    this.cloneController.append({
      product_combo_id: selected.value,
      name: selected.text,
    });
  }

  removeItem() {
    this.itemsController.removeItem(event);
  }

  get cloneController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "clone"
    );
  }
}
