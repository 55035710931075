import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["biller", "phoneError", "tel"];

  connect() {
  }

  get telController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "tel"
    );
  }

  setPhoneNumber(event) {
    event.preventDefault();
    if (this.validatePhone(this.phone)) {
      this.telTarget.value = this.phone;
    }
  }

  phoneChanged(event) {
    event.preventDefault();

    if (event.target.value.charAt(0) === "0") {
      event.target.value = event.target.value.slice(1);
    }

    this.phone =
      this.telController.iti.selectedCountryData.dialCode + event.target.value;

    if (this.validatePhone(this.phone)) {
      event.target.classList.remove("border-danger");
      this.phoneErrorTarget.textContent = "";
    } else {
      event.target.classList.add("border-danger");
      this.phoneErrorTarget.textContent = "Invalid phone";
    }
  }

  validatePhone(phone) {
    if (phone.length == 12) {
      return true;
    } else {
      false;
    }
  }

  checkBillers(biller_ids) {
    var billers = this.billerTarget.querySelectorAll("input[type=checkbox]");
    billers.forEach((biller) => {
      if (biller_ids.includes(parseInt(biller.value))) {
        biller.checked = true;
      }
    });
  }

}
