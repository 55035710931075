import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["refundTemplate", "depositTemplate", "returnForm", "submit"];

  // static values = {
  //   itemizable: String
  // };

  showRefundForm(event) {
    var content = this.refundTemplateTarget.innerHTML;
    this.refundTemplateTarget.insertAdjacentHTML("afterend", content);
    event.target.disabled = true;
  }

  showDepositForm(event) {
    var content = this.depositTemplateTarget.innerHTML;
    this.depositTemplateTarget.insertAdjacentHTML("afterend", content);
    event.target.disabled = true;
  }

  enableForm() {
    this.submitTarget.disabled = false;
}
}
